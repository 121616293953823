.dark-theme {
  --body-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.about {
  background-color: var(--body-color);
}

.about__container {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
}

.about__img {
  width: 500px;
  border-radius: 1.5rem;
  justify-self: center;
}


.about__info {
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}

.about__box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.7rem;
  text-align: center;
  padding: 1rem 1.25rem;
    /* box-shadow: 0 12px 16px rgba(247, 171, 4, 0.7); */
}

.dark-theme .about__box {
  background-color: #2b2a2a;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.about__icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}

.about__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.about__subtitle {
  font-size: 0.8rem;
}

.dark-theme .about__subtitle {
  color: #ffffff;
}

.dark-theme .section__subtitle {
  color: #ffffff;
}

.about__description {
  padding: 0 4rem 0 0;
  margin-bottom: 0px;
}

.dark-theme .about__description {
  color: #ffffff;
}

/* From Uiverse.io by eslam-hany */ 
.book {
  position: relative;
  border-radius: 10px;
  width: 450px;
  height: 300px;
  background-color: whitesmoke;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
  margin-left: 10px;
  border: 1px solid #F7AB04;
}

.cover {
  top: 0;
  position: absolute;
  background-color: var(--container-color);;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  border: 1px solid #F7AB04;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.book:hover .cover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-80deg);
  -ms-transform: rotatey(-80deg);
  transform: rotatey(-80deg);
}

/* Container for the stacked images */
.image-stack-section {
  display: flex;
  justify-content: flex-start; /* Align the stack to the left */
  align-items: center;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any default margin */
}

.image-stack {
  position: relative;
  width: 185px; /* Adjust width based on the image size */
  height: 390px;
  margin-left: 115px; /* Align the stack closer to the left */
}

/* Image container with red box */
.image-box {
  border: 3px solid #F7AB04; /* Red box around each image */
  position: relative;
  width: 100%;
  margin-bottom: 30px; /* Space between each image */
  border-radius: 10px;
}

.image-stack-img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 12px rgba(247, 171, 4, 0.5); /* Light shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* First image */
.img1 {
  transform: rotate(0deg); /* Rotate the first image slightly */
    margin-bottom: 10px; /* Overlap the first image */
  margin-left: 109px;
}

/* Second image with partial overlap */
.img2 {
  transform: rotate(0deg); /* Rotate the second image */
  margin-left: 31px;
}

/* Third image with partial overlap */
.img3 {
  transform: rotate(0deg); /* Rotate the third image */
  margin-top: -23px; /* Overlap the second image */
  margin-left: 113px; /* Overlap the second image */
}

.image-box:hover {
  z-index: 10; /* Bring the hovered image to the front over all other images */
}

/* Hover effects to highlight individual images */
.img1:hover .image-stack-img {
  transform: rotate(0deg) scale(1.7); /* Rotate by 20 degrees and enlarge */
  box-shadow: 0 12px 16px rgba(247, 171, 4, 0.7); /* Stronger shadow when hovered */
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
}

/* Hover effect for the second image */
.img2:hover .image-stack-img {
  transform: rotate(0deg) scale(1.7); /* Rotate by -20 degrees and enlarge */
  box-shadow: 0 12px 16px rgba(247, 171, 4, 0.7); /* Stronger shadow when hovered */
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
}

/* Hover effect for the third image */
.img3:hover .image-stack-img {
  transform: rotate(0deg) scale(1.7); /* Rotate by 50 degrees and enlarge */
  box-shadow: 0 12px 16px rgba(247, 171, 4, 0.7); /* Stronger shadow when hovered */
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
}

/* Responsive adjustments for smaller screens */
/* Adjustments for smaller mobile devices */
@media screen and (max-width: 576px) {
  .image-stack {
    width: 120px; /* Reduce width for mobile */
    margin-left: 15px; /* Align the stack to the center */
    height: auto;
  }

  /* First image */
  .img1 {
    transform: rotate(0deg); /* Adjust rotation for mobile */
  }

  /* Second image with partial overlap */
  .img2 {
    transform: rotate(0deg); /* Adjust rotation for mobile */
  }

  /* Third image with partial overlap */
  .img3 {
    transform: rotate(0deg); /* Adjust rotation for mobile */
  }

  /* Adjust hover effects on smaller screens */
  .image-stack-img:hover {
    transform: rotate(0deg);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2); /* Reduce shadow on mobile */
  }
}




@media screen and (max-width: 992px) {
  .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
  .about__img {
    width: 228px;
  }
  .about__box {
    padding: 0.75rem 0.5rem;
  }
  .about__data {
    text-align: center;
  }
  .about__info {
    justify-content: center;
  }
  .about__description {
    padding: 0 8rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .about__info {
    grid-template-columns: repeat(3, 1fr);
  }
  .about__description {
    padding: 0;
    margin-top: 30px;
    margin-right: 10px;
  }
  .book {
  position: relative;
  border-radius: 10px;
  width: 320px;
  height: 300px;
  margin-left: 20px;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .about__info {
    grid-template-columns: repeat(2, 1fr);
  }
}
