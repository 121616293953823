.dark-theme {
  --body-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.work {
  background-color: var(--body-color);
}

.work__filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--mb-2);
  column-gap: 0.75rem;
}

.work__item {
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
}

.work__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.dark-theme .work__item:hover {
  color: #313131;
}

.work__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.dark-theme .work__card {
  background-color: #313131;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.work__card {
  position: relative;
}

.work__modal-copy {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
}

.work__modal-copy button {
  margin-left: px;
}



.work__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.work__overlay a {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
  transition: transform 0.3s ease;
}

.work__overlay a:hover {
  background-color: rgb(15, 15, 15);
  transform: scale(1.1);
}

.work__overlay a:first-child {
  animation: bounce 1s infinite;
}

.work__overlay a:last-child {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.work__overlay i {
  color: white;
  font-size: 24px;
  margin: 0 10px;
}

.work__card:hover .work__overlay {
  opacity: 1;
}

.work__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.dark-theme .work__button-icon {
  color: #ffffff;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.active-work {
  background-color: var(--title-color);
  color: var(--container-color);
}

.dark-theme .active-work {
  color: #313131;
}

.work__modal {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.work__modal.active-modal {
  opacity: 1;
  visibility: visible;
}

.work__modal-content {
  width: 700px;
  position: relative;
  background-color: var(--container-color);
  padding: 3.5rem;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
}

.work__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.work__modal-img {
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.work__modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.work__modal-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.work__modal-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.work__divider {
  width: 100%;
  height: 2px;
  background-color: #f7ab04; /* Yellow divider line */
  margin: 1rem 0;
}


.work__modal-description {
  font-size: 1rem;
  color: var(--title-color);
  margin-bottom: 1rem;
}

.work__modal-techStack {
  font-size: 1rem;
  color: var(--title-color);
  margin-bottom: 1.5rem;
}

.work__button-icon {
  font-size: 1rem;
  transition: transform 0.3s, color 0.3s;
}

.work__modal-link:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.work__modal-link:focus, 
.work__modal-link:active {
  color: inherit;
  outline: none;
}

.work__modal-link {
  text-decoration: none;
  color: inherit; 
}

.work__modal-techStack {
  display: flex;
  align-items: center;
}

.work__techStack-icons {
  display: flex;
  gap: 15px; /* Space between icons */
  margin-left: 10px; /* Space between 'Tech Stack' label and icons */
}

.work__techStack-icon {
  width: 35px; /* Icon size */
  height: auto;
}


@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }
  .work__card {
    padding: 1rem;
  }
  .work__img {
    margin-bottom: 0.75rem;
  }
  .work__title {
    margin-bottom: 0.25rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
  .work__modal-description {
  font-size: 0.8rem;
  color: var(--title-color);
  margin-bottom: 0.8rem;
}

.work__modal-techStack {
  font-size: 0.8rem;
  color: var(--title-color);
  margin-bottom: 1.2rem;
}

.work__techStack-icons {
  display: flex;
  gap: 7px; /* Space between icons */
  margin-left: 10px; /* Space between 'Tech Stack' label and icons */
  width: 30px;
}


}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }
  .work__img {
    width: 100%;
  }
}

/* For small devices */
@media screen and (max-width: 400px) {
  .work__item {
    font-size: var(--small-font-size);
  }
  .work__filters {
    column-gap: 0.25rem;
  }
}
