.dark-theme {
  --body-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.skills {
  background-color: var(--body-color);
}

.skills__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Make columns dynamic based on content */
  gap: 2rem; /* Control the space between columns */
  justify-items: center; /* Center the content within each grid item */
  align-items: start; /* Align items at the start */
}

/* Individual skill content boxes */
.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 1.25rem;
  box-sizing: border-box; /* Make sure padding is included in the height/width */
  width: 100%; /* Make sure the content takes full width of the container */
}
.dark-theme .skills__content{
  background-color: #313131;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: grid; /* Change flex to grid */
  grid-template-columns: repeat(2, 1fr); /* Use grid instead of a large column-gap */
  gap: 4rem; /* Reduce spacing */
  margin-left: 40px;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 2rem;
}

.skills__data {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: auto;
    row-gap: 2rem;
  }
  .skills__content {
    padding: 1.5rem;
  }
  .skills__box {
    column-gap: 1.25rem;
    margin-left: 30px;
    gap: 1rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}