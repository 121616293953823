.dark-theme {
  --body-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.contact {
  background-color: var(--body-color);
}

.contact__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
}

.contact__title {
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.dark-theme .contact__card-title,
.dark-theme .contact__card-icon {
  color: #fff;
}

.contact__info {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 300px;
}

.contact__card {
  background-color: var(--container-color);
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
}

.dark-theme .contact__card {
  background-color: #313131;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.contact__card-icon {
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-25);
}
.contact__card-title,
.contact__card-data {
  font-size: var(--small-font-size);
}

.dark-theme .contact__card-title,
.dark-theme .contact__card-data,
.dark-theme .contact__button,
.dark-theme .contact__button-icon {
  color: #fff;
}

.contact__card-title {
  font-weight: var(--font-medium);
}

.contact__card-data {
  display: block;
  margin-bottom: var(--mb-0-75);
}
.contact__button {
  color: var(--text-color);
  display: inline-flex;
  font-size: var(--small-font-size);
  align-items: center;
  column-gap: 0.25rem;
}

.contact__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.contact__button:hover .contact__card-icon {
  transform: translate(0.25rem);
}

.contact__form-container {
  background-color: #fff; /* White background */
  border-radius: 1rem; /* Rounded corners */
  padding: 2rem; /* Padding inside the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
  height: auto;
  max-width: 660px; /* Increase max width */
  width: 100%; /* Set width to 100% for responsiveness */

}

.contact__form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem; /* Increase gap for better spacing */
  margin-bottom: 1.5rem;
}

.contact__form-div {
  position: relative;
  height: 4rem;
}


.contact__form-input {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: none;
  color: var(--text-color);
  outline: none;
  padding: 1.5rem;
  border-radius: 0.75rem;
  z-index: 1;
  background-color: #fff; /* White background for input */
  font-size: 1rem; /* Adjust font size */
}

.contact__form-tag {
  position: absolute;
  top: -0.6rem; /* Adjust the position to give space */
  left: 1rem; /* Add a bit more spacing from the left */
  font-size: var(--small-font-size);
  background-color: white; /* Ensure label background color doesn't overlap with input */
  padding: 0 0.25rem; /* Add small padding to create space around the text */
  z-index: 10;
}
.contact__form-input:focus + .contact__form-tag,
.contact__form-input:not(:placeholder-shown) + .contact__form-tag {
  top: -0.8rem; /* Move the label up when input is focused or not empty */
  font-size: 0.75rem; /* Reduce the label font size */
  color: var(--title-color); /* Change the label color */
  background-color: #fff;
}

.contact__form-area {
  height: 11rem;
  grid-column: span 2;
}

.contact__form-area textarea {
  width: 100%;
  height: 100%;
  resize: none;
  background-color: #fff; /* White background for textarea */
}

.button {
  color: #fff; /* White text */
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.button span {
  color: #f7ab04; /* Custom color for 'Send' text */
}

.input-container {
  position: relative;
}

.contact__form-input.with-icon {
  padding-right: 2.5rem; /* Add padding to make space for the icon */
}

.contact__form-icon {
  position: absolute;
  right: 1rem; /* Position the icon on the right */
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.5); /* Icon color */
  font-size: 1.5rem; /* Adjust icon size */
  pointer-events: none; /* Ensure the icon does not block input clicks */
  z-index: 999;
}


.dark-theme .contact__form-container {
  background-color: #313131;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dark-theme .contact__form-input {
  background-color: #313131;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #fff; /* White text for input fields */
}

.dark-theme .contact__form-input::placeholder {
  color: rgba(255, 255, 255, 0.5); /* Light placeholder text color */
}

.dark-theme .contact__form-tag {
  color: #fff; /* White label color */
  background-color: #313131;
}

.dark-theme .contact__form-area textarea {
  background-color: #313131;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #fff; /* White text for textarea */
}



.dark-theme .contact__button {
  color: #fff; /* White text for button in the card */
}

.dark-theme .contact__button:hover {
  background-color: #f7ab04; /* Hover color for button */
}

/* Additional styles for icons in the dark theme */
.dark-theme .contact__form-icon {
  color: rgba(255, 255, 255, 0.7); /* Light icon color */
}

/* Make adjustments to the card title in dark theme */
.dark-theme .contact__card-title {
  color: #fff; /* White text for card title */
}

/* Make adjustments to the card data in dark theme */
.dark-theme .contact__card-data {
  color: rgba(255, 255, 255, 0.7); /* Light text color for card data */
}


@media screen and (max-width: 992px) {
  .contact__container {
    column-gap: 3;
  }
}


/* For medium devices */
@media screen and (max-width: 768px) {
  .contact__container {
    flex-direction: column; /* Change layout to column for smaller screens */
    row-gap: 3rem; /* Add some spacing between the elements */
  }
  .contact__info {
    justify-content: center;
  }
  .contact__form-container {
    margin: 0.1rem;
    height: 650px;
  }
  .contact__form-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Make form fields full width */
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  .contact__form {
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .contact__info {
    grid-template-columns: 1fr;
  }
  .contact__form {
    width: 100%;
  }
  .contact__form-container {
    margin: 0.5rem; /* Add margin for small screens */
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
  .contact__form-grid {
    gap: 1rem; /* Reduce gap for smaller screens */
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
