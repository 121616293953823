.dark-theme {
  --container-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.footer {
  background-color: var(--body-color);
}

.footer__container {
  padding: 2rem 0 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer__title,
.footer__link {
  color: var(--title-color);
}

.footer__title {
  text-align: center;
  margin-bottom: var(--mb-2);
}

.footer__link {
  color: var(--title-color-dark);
}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer__social-link {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
}

.dark-theme .footer__social-link {
  background-color: #212121;
  color: #ffffff;
}

.footer__social-link:hover {
  background-color: var(--title-color-dark);
}

.dark-theme .footer__social-link:hover {
  background-color: #000;
}

.footer__copy {
  display: block;
  margin-top: 4rem;
  color: var(--title-color);
  text-align: center;
  font-size: var(--small-font-size);
}

@media screen and (max-width: 992px) {
  .footer__social-link {
    font-size: 1.1rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
  }
}
