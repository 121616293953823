.dark-theme {
  --body-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.home {
  background-color: var(--body-color);
}

/* Dark mode style for the SVG */
.dark-theme .button__icon {
  fill: var(--title-color); /* Change the fill color to match the title color */
}

.home__container {
  row-gap: 5rem;
  position: relative;
  top: 40px;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5 rem;
  column-gap: 2rem;
  align-items: center;
  margin-top: 1rem;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1.7rem;
  align-items: center;
}

.home__social-icon {
  font-size: 1.5rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.home__hand {
  width: 38px;
  height: 38px;
  margin-left: 0.4rem;
}

.home__subtitle {
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  padding-left: 5.4rem;
  margin-bottom: var(--mb-1);
}

.home__description {
  max-width: 400px;
  margin-bottom: 16px;
}

.dark-theme .home__description {
  color: var(--title-color);
}

.home__subtitle::before {
  position: absolute;
  content: "";
  width: 70px;
  height: 2px;
  background-color: var(--text-color);
  top: 15px;
  left: 0;
}

.home__img {
  background-image: url(../../assets/Profile4.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 7px #f8bb38;
  order: 1;
  justify-self: center;
  width: 260px;
  height: 260px;
  animation: profile__animation 6s ease-in-out infinite 1s;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  margin-top: 1rem;
}

.letter-spacing::before {
  content: " ";
}

.letter-spacing::after {
  content: " ";
}


/* @keyframes profile__animation {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
} */

.home__scroll {
  margin-left: 9.25rem;
  position: relative;
  bottom: 10px;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

.github,
.linkedin {
  transition: width 1s, height 1s, transform 1s;
}

.github:hover,
.linkedin:hover {
  transform: scale(1.4);
}

@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__hand {
    width: 26px;
    height: 26px;
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 1rem;
  }


  .home__img {
    width: 250px;
    height: 250px;
    /* box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%); */
  }

  .home__scroll {
    margin-left: 7.5rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }
  .home__img {
    order: initial;
    justify-self: initial;
  }
  .home__data {
    grid-column: 1/3;
  }
  .home__img {
    width: 200px;
    height: 200px;
    /* box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%); */
    position: relative;
    right: 35px;
  }
  .home__scroll {
    display: none;
  }

  .home__skills {
    display: none;
  }

  .home__social {
    position: relative;
    bottom: 180px;
    display: flex;
    gap: 20px;
    width: 80px;
  }

  .home__subtitle {
    font-size: 1.1rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
  }
  .home__hand {
    width: 22px;
    height: 22px;
  }
}

