/* Loader.css */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #fafafa;
}

.circular-loader {
    width: 100px;
    height: 100px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #F7AB04;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

.text-loader {
    font-family: Arial, sans-serif;
    font-size: 48px;
    font-weight: bold;
    display: flex;
    perspective: 1000px;
}

.letter {
    display: inline-block;
    opacity: 0;
    transform: translateZ(-100px);
    margin: 0 2px;
    animation: appear 3s forwards;
}

.letter:nth-child(1) { animation-delay: 0s; }
.letter:nth-child(2) { animation-delay: 0.5s; }
.letter:nth-child(3) { animation-delay: 1s; }
.letter:nth-child(4) { animation-delay: 1.5s; }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes appear {
    0%, 100% {
        opacity: 0;
        transform: translateZ(-100px);
    }
    12.5%, 37.5% {
        opacity: 1;
        transform: translateZ(0);
    }
}
