body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #f7ab04;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body {
  cursor: url("./images/cursor.png"), auto;
}

body.modal-open {
  overflow: hidden;
}

html.modal-open {
  overflow: hidden;
}