.dark-theme {
  --body-color: #313131; /* Background color for dark theme */
  --title-color: #ffffff; /* Text color for dark theme */
  --title-color-dark: #ffffff; /* Text color for dark theme when active or on hover */
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transform: 0.3s;
}

.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

.active-link {
  color: #f7ab04; /* Yellow color for active link */
  font-weight: bold; /* Bold for active link */
}


.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

.toggle-sun {
  color: #f7ab04;
  font-size: 1.5rem;
}

.toggle-moon {
  color: #fff;
  font-size: 1.2rem;
}

.toggle-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--title-color);
  transform: 0.3s;
}

/* label {
  width: 80px;
  height: 40px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}

label::after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  left: 5px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

input {
  height: 0;
  width: 0;
  visibility: hidden;
}

input:checked + label::after {
  background: #242424;
}

input:checked + label:after {
  left: 75px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
} */

@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transform: 0.3s;
  }

  .show-menu {
    bottom: 0;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav__ .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .sunMoon {
    position: fixed;
    top: 40px;
    right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sun,
  .moon {
    width: 50px; /* Adjust the width of the icons as needed */
    cursor: pointer;
  }
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }
}
